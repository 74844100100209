<template>
<article class="columns">
  <section class="column">
    <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowDialog }">
    <div class="modal-background"></div>
    <div class="modal-card scrollable" style="width: auto">
        <form action="" class="box" @submit.prevent="submitForm">
        <header class="modal-card-head">
        <p class="modal-card-title">Employee Details</p>
        <button class="delete is-danger" type="button" @click="closeDialog">Cancel</button>
        </header>
        <section class="modal-card-body">

            <div class="columns">
                <div class="column">
                    <b-field label="First Name *" label-for="firstName">
                        <b-input custom-class="input is-danger" required icon-pack="fas" icon="user" v-model="employee.firstName" validation-message="Please enter First Name"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Last Name *" label-for="lastName">
                        <b-input custom-class="input is-danger" required icon-pack="fas" icon="user-alt" v-model="employee.lastName" validation-message="Please enter Last Name"></b-input>
                    </b-field>
                </div>

                <div class="column">
                    <b-field label="Mobile Phone *" label-for="cellPhone">
                        <b-input 
                            custom-class="input is-danger"
                            type="tel"  
                            required 
                            icon-pack="fas" 
                            icon="mobile" 
                            v-model="employee.cellPhone" 
                            validation-message="Please enter Phone Number"
                            ></b-input>
                    </b-field>
                </div>

                <div class="column is-2">
                    <b-field label="Active" label-for="active">
                        <!-- <b-checkbox  v-model="employee.active">

                        </b-checkbox> -->

                        <b-switch 
                            size="is-small"
                            v-model="employee.active"
                            type="is-success">
                                
                        </b-switch>                        
                    </b-field>
                </div>
            </div>

            <div class="columns">

                <div class="column">
                    <b-field label="Email" label-for="email">
                        <b-input type="email" icon-pack="fas" icon="envelope" v-model="employee.email" validation-message="Please enter a vaild email address"></b-input>
                    </b-field>
                </div>

                <div class="column">
                    <b-field label="Employee SSN" label-for="ssn">
                        <b-input type="password" icon-pack="fas" icon="mobile" v-model="employee.ssn" validation-message="Please enter SSN"></b-input>
                    </b-field>
                </div>                
            </div>

            <div class="columns">
                <div class="column">
                    <b-field label="Hire Date" label-for="hireDate">
                       <b-datepicker
                            :max-date="maxDate"
                            icon="calendar"
                            placeholder="Please select Hire Date"
                            v-model="employee.hireDateObj">
                        </b-datepicker>
                    </b-field>
                </div>

                <div class="column" v-if="employee.active == false ">
                    <b-field label="Termination Date" label-for="cellNumber">
                       <b-datepicker
                            :max-date="maxDate"
                            icon="calendar"
                            required
                            placeholder="Please select Termination Date"
                            v-model="employee.terminationDateObj">
                        </b-datepicker>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Payroll Type" label-for="cellNumber">
                        <b-select v-model="employee.payrollType">
                            <option value="HOURLY">Hourly</option>
                        </b-select>
                    </b-field>
                </div>

                <div class="column">
                    <b-field label="Hourly Rate" label-for="cellNumber">
                        <b-input type="text" icon-pack="fas" icon="mobile" v-model="employee.rate" validation-message="Please enter Payroll Rate"></b-input>
                    </b-field>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <b-field label="Address" label-for="addressLine1">
                        <b-input type="text" icon-pack="fas" icon="building" v-model="employee.addressLine1" validation-message="Please enter Street adddress"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="City" label-for="city">
                        <b-input type="text" icon-pack="fas" icon="building" v-model="employee.city" validation-message="Please enter City"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="State" label-for="state">
                        <b-input type="text" icon-pack="fas" icon="building" v-model="employee.state" validation-message="Please enter State"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Zip Code" label-for="zipCode">
                        <b-input type="text" icon-pack="fas" icon="building" v-model="employee.zipCode" validation-message="Please enter Zip Code"></b-input>
                    </b-field>
                </div>            
            </div>


            <div class="columns">
                <div class="column">
                    <b-field label="Emergency Contact Name" label-for="contactName">
                        <b-input type="text" icon-pack="fas" icon="user-tie" v-model="employee.emergency_contact_name" validation-message="Please enter Contact Name"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Contact Cell Phone" label-for="cellNumber">
                        <b-input name="cellNumber" id="cellNumber" type="tel" icon-pack="fas" icon="mobile" v-model="employee.emergency_contact_phone" validation-message="Please enter Contact Cell Phone Number"></b-input>
                    </b-field>
                </div>
            </div>

        </section>

        <footer class="modal-card-foot">
        <button class="button is-success" type="button" @click="submitForm" :disabled='isDisabled'>Save</button>
        <button class="button" type="button" @click="closeDialog">Cancel</button>
        <span class='is-danger'></span>
        </footer>
    </form>

    </div>
    </div>
  </section>
</article>
</template>


<script>

export default {
    props: {
         'canCancel': String, 
         'isShowDialog': Boolean,
         'employee': {},
         'storeId': Number,
         'id': Number,
         'action': String
    },

    data() {
        return {
            maxDate: new Date(),
            // dateFormatter: (date) => new Intl.DateTimeFormat({ timeZone: "UTC" }).format(date)
        }
    },

    methods: {

        closeDialog() {
            this.$emit('hide-dialog')
            this.email = ""
            this.role = ""
        },

        submitForm() {

            this.$emit('save-record')
            this.closeDialog()

        },

    },

    computed: {

        isDisabled() {
            if (this.employee.firstName && 
                this.employee.lastName && 
                this.employee.cellPhone  
                // this.employee.email && 
                // this.employee.addressLine1 && 
                // this.employee.city && 
                // this.employee.state &&
                // this.employee.zipCode 
                ) {
                    return false
                } else {
                    return true
                }

        }

    },

}
</script>

<style scoped>

/* .scrollable {
    max-height: 200vw;
    overflow: auto; 
} */


</style>