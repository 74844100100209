<template>
    <div class="panel has-text-weight-semibold" v-if="getActiveStore.role != 'STAFF'">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">EMPLOYEES</div>
            </div>
        </div>
        <hr>
        <b-table 
            :data='employees'
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort=delivery.endDate
            defaultSortDirection='asc'
            >

            <template>

                <b-table-column v-slot="props" class="is-2 has-text-right" field="amount" label="Edit" sortable sorticon>
                <b-button class="is-primary is-small"  v-on:click="doAction('edit',props.row)" outlined rounded><i class="far fa-edit"></i></b-button>
                </b-table-column> 

                <b-table-column field="active" label="Active" v-slot="props">
                    <i class="fas fa-check-circle  has-text-success" v-if="props.row.active"></i>
                    <i class="fas fa-dot-circle  has-text-danger" v-else></i>
                </b-table-column>

                <b-table-column field="Name" label="Name" sortable sorticon v-slot="props">
                    {{ props.row.firstName }} {{ props.row.lastName }}
                </b-table-column>

                <b-table-column field="email" label="Email" sortable sorticon v-slot="props">
                    {{ props.row.email }}
                </b-table-column>

                <b-table-column field="phoneNumber" label="Phone Number" sortable sorticon v-slot="props">
                    {{ props.row.cellPhone | formatUSAPhoneNumber}}
                </b-table-column>

                <b-table-column field="hireDate" label="Hire Date" sortable sorticon v-slot="props">
                    {{ props.row.hireDate | formatDate}}
                </b-table-column>
                <!-- <b-table-column class="" v-slot="props">
                        <i class="button is-info" v-on:click="doAction('edit',props.row)">
                            <i class="fas fa-edit"></i>
                        </i>
                </b-table-column> -->
                
            </template>
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>

            <template slot="top-left">
                <a class="button is-primary" v-on:click="doAction('add')">
                    <i class="fas fa-money-check"><span class="btn-label-text"> New</span></i>
                </a>
            </template>

            <template slot="bottom-left">
                <a class="button is-primary" v-on:click="doAction('add')">
                   <i class="fas fa-money-check"><span class="btn-label-text"> New</span></i>
                </a>
            </template>
        </b-table>

        <employee-form
            :storeId="storeId"
            :employee="employeeTemp"
            :action="action"
            :isShowDialog="isShowDialog"  
            @hide-dialog="toggleDialog"
            @save-record="saveRecord"
            @refresh-data="fetchRecords">        
        >
        </employee-form>


    </div>
</template>

<script>
import UserStore from "../../../../store/UserStore"

import DataLoading from '../../../../components/app/DataLoading.vue'

import axios from 'axios'

import EmployeeForm from './EmployeeForm'
import moment from 'moment'

export default {

    components: {
        DataLoading,
        EmployeeForm,

    },

    metaInfo: {
        title: 'Employees'
    },

    data() {
        return {
            employees: [],
            employee: {},
            emptyEmployee: {
                "active": true,
                "firstName": "",
                "lastName": "",
                "ssn": "",
                "mobilePhone": "",
                "email": "",
                "street" : "",
                "city": "",
                "state": "IL",
                "zip": "",
                "phone": "",
                "rate": 0,
                "hireDateObj": moment(),
                "terminationDateObj": null,
                "hireDate": moment().format("YYYY-MM-DD"),
                "terminationDate": null,
                "payrollType": "HOURLY",
                "emergency_contact_phone": ""
            },            
            employeeTemp: {},
            isPaginated: true,
            perPage: 10,
            isLoading: true,
            isFullPage: false,
            isShowDialog: false,
            action: '',
            storeId: 0,
            userId: 0,
            email: '',
            role: '',
            deleteAllowed: true,

        }
    },

    methods: {

        fetchRecords() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/employees'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.employees = response.data.data
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        doAction(action, employee) {

            this.action = action
            this.storeId = this.user.activeStore

            switch(this.action) {
                case 'add':
                    this.employee = {}
                    this.employee.hireDate = new Date()
                    this.employee.active = true
                    this.employeeTemp = Object.assign({}, employee)
                    this.employeeTemp.active = true
                    this.employeeTemp.hireDateObj = new Date()
                    this.employeeTemp.terminationDateObj = null
                    this.employeeTemp.hireDate = null
                    this.employeeTemp.terminationDate = null
                    this.toggleDialog()                   
                    break

                case 'edit':
                    this.employee = employee
                    this.employee.hireDateObj = new Date(this.employee.hireDate)
                    if (this.employee.active) {
                        this.employee.terminationDateObj = null
                        this.employee.terminationDate = null
                    } else {
                        if (this.employee.terminationDate) {
                            this.employee.terminationDateObj = new Date(this.employee.terminationDate)
                        } else {
                            this.employees.terminationDateObj = null
                        }
                        
                    }
                    this.employeeTemp = Object.assign({}, employee)
                    this.toggleDialog()
                    
                    break
            }
        },

        toggleDialog() {
            this.isShowDialog = ! this.isShowDialog
        },


        saveRecord() {
            this.isLoading = true

            // this.employeeTemp.hireDate = this.employeeTemp.hireDateObj.format('YYYY-MM-DD')
            this.employeeTemp.hireDate = this.employeeTemp.hireDateObj.getFullYear() + 
                        '-' + ("0" + (this.employeeTemp.hireDateObj.getMonth() + 1)).slice(-2) + 
                        '-' + ("0" + this.employeeTemp.hireDateObj.getDate()).slice(-2)


            if (this.employeeTemp.active) {
                this.employeeTemp.terminationDate = null
            } else {
            // this.employeeTemp.hireDate = this.employeeTemp.terminationDateObj.format('YYYY-MM-DD')
                if (this.employeeTemp.terminationDateObj != null) {
                    this.employeeTemp.terminationDate = this.employeeTemp.terminationDateObj.getFullYear() + 
                                '-' + ("0" + (this.employeeTemp.terminationDateObj.getMonth() + 1)).slice(-2) + 
                                '-' + ("0" + this.employeeTemp.terminationDateObj.getDate()).slice(-2)
                } else {
                    this.employeeTemp.terminationDate = null
                }

            }  

            if (this.employeeTemp.id) {
                this.updateRecord()
            } else {
                this.addRecord()
            }
            
            //
        },

        updateRecord() {

            const url = process.env.VUE_APP_API_ROOT_URL + '/stores/employee/'  
                        + this.employeeTemp.id

            axios.put(url, 
                this.employeeTemp,
            {
                headers: {
                    'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                    'Refresh-Token': UserStore.getters.getRefreshToken
                },
            })
            .then( () => {
                this.fetchRecords()
            })
            .catch ( (err) => {
                console.log(err.response.status)
            })
            this.isLoading = false                  
        },

        addRecord() {

            const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                        + '/employee'
            axios.post(url, 
                this.employeeTemp,
            {
                headers: {
                    'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                    'Refresh-Token': UserStore.getters.getRefreshToken
                },
            })
            .then( () => {
                this.fetchRecords()
            })
            .catch ( (err) => {
                console.log(err.response.status)
            })
            this.isLoading = false                  
        }, 

        getDeleteToolTip(delname) {
            return 'Delete Employee for ' + delname
        },
        
        getEditToolTip(delname) {
            return 'Edit Employee for ' + delname
        },              

    },

    computed: {
        // getUser() {
        //     return UserStore.getters.getUser
        // },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },
    },

    mounted() {
        this.user = this.getUser()
        if (this.getActiveStore.role != "STAFF") {
            this.fetchRecords()
        }
    }

}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>